import { PortalServico } from '@/utils/';

export default class LicitacoesServico extends PortalServico {
  async listarCampus(){
    return await this.get('wsinfo/api?name=campus&sortBy=codcam&desc=N');
  }
  async listarUnidades(codcam){
    if (codcam != null) {
      return await this.get(`wsinfo/api?name=uniddesp&length=1000&lq=codcam:${codcam}&sortBy=codunddsp&desc=N`);
    }
    return await this.get(`wsinfo/api?name=uniddesp&length=1000&sortBy=codunddsp&desc=N`);
  }
  async listarLicitacoes(dados, currentPage, token) {
    var queryLucene = this.escreveQueryLucene(dados)
    var start = (currentPage - 1)*10
    return await this.get(`wsinfo/api?name=compra&length=10&start=${start}${queryLucene}`, {
      "g-recaptcha-token": token
    });
  }
  escreveQueryLucene(dados) {
    var queryLucene = '&lq='
    for (const chave in dados) {
      var valor = dados[chave]
      switch (valor) {
        case '':
          break;
        default:
          if (queryLucene.length > 5) {
            queryLucene += ' AND '
          }
          switch (chave) {
            case "objcpr":
              valor = valor.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
              queryLucene += `${chave}:${valor}~0.8`
              break;
            case "numleimdldsp":
              queryLucene += `${chave}:"${valor}"`
              break;
            default:
              queryLucene += `${chave}:${valor}`
              break;
          }
          break;
      }
    }
    return queryLucene
  }
  async obterLicitacao(codpcddsp, token){
    return await this.get(`wsinfo/api?name=compra&lq=codpcddsp:${codpcddsp}`, {
      "g-recaptcha-token": token
    })
  }
  async listarItens(codpcddsp){
    return await this.get(`wsinfo/api?name=compraitem&length=1000&sortBy=numord&desc=N&lq=codpcddsp:${codpcddsp}`)
  }
  async listarArquivos(codpcddsp) {
    return await this.get(`wsinfo/api?name=compraarq&length=1000&sortBy=numseq&desc=N&lq=codpcddsp:${codpcddsp}`)
  }
  async listarLeis() {
    return await this.get(`wsinfo/api?name=compralei`)
  }
  async listarModalidades(numleimdldsp) {
    if (numleimdldsp != null) {
      return await this.get(`wsinfo/api?name=compramod&length=1000&sortBy=nommdldsp&lq=numleimdldsp:"${numleimdldsp}"`)
    }
    return await this.get(`wsinfo/api?name=compramod&length=1000&sortBy=nommdldsp`)
  }
  async listarSituacoes() {
    return await this.get(`wsinfo/api?name=comprasit&length=1000&sortBy=sitcpr`)
  }
  obterArquivo(codarq_fs) {
    return `https://uspdigital.usp.br/wsinfo/arquivo?name=compraarq&codarq_fs=${codarq_fs}`
  }
}