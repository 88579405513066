<template>
  <div>
    <uspCartao
      titulo="Listagem de Contratações"
      :por-pagina="10"
      class="licitacoes"
    >
      <template #corpo>
        <div
          v-if="carregando"
          class="carregando-lista"
        >
          <uspSpinner
            style="width: 10rem; height: 10rem; border-width: 0.8rem;"
          />
        </div>
        <section v-if="!carregando">
          <p class="ml-2">
            <small 
              id="filtro-lista-help"
              class="form-text text-muted"
            >
              Página {{ currentPage }} - Mostrando {{ listaLicitacoes.length }} de {{ totalResults }} resultados encontrados
            </small>
          </p>
          <div 
            v-if="listaLicitacoes.length === 0"
            class="d-flex justify-content-center align-items-center flex-column mt-5"
          >
            <img 
              src="/imgs/usp-tarefas.svg"
              alt="Ilustração de uma pilha de tarefas concluídas"
            >
            <p class="font-weight-bold text-center mt-3">
              &nbsp; Não há contratações com os filtros selecionados.
            </p>
          </div>
          <div class="list-group">
            <section
              v-for="conteudo in listaLicitacoes"
              :key="conteudo.codpcddsp"
              class="list-group-item"
            >
              <div>
                <strong>Contratação nº {{ conteudo.numcpr }}/{{ conteudo.anocpr }}</strong> <span class="mr-2">- {{ conteudo.nomunddsp }} ({{ conteudo.sglunddsp }})</span>
                <span>
                  <strong>Campus:</strong> {{ conteudo.nomcam }}
                </span>
              </div>
              <div class="row-compra">
                <span>
                  <strong>Compra:</strong>
                  {{ conteudo.codpcddsp }}
                </span>
                <span>
                  <strong>Processo:</strong>
                  {{ conteudo.numpro }}
                </span>
                <span>
                  <strong>Modalidade:</strong>
                  {{ conteudo.nommdldsp }} <span v-if="conteudo.cplnommdldsp">- {{ conteudo.cplnommdldsp }}</span>
                </span>
              </div>
              <span v-if="conteudo.codoftcpr!=null">
                <strong>Oferta de Compra BEC:</strong> {{ conteudo.codoftcpr }}
              </span>
              <span>
                <strong>Objeto:</strong> {{ conteudo.objcpr }}<em
                  v-if="!conteudo.objcpr"
                  class="text-muted"
                >Não há.</em>
              </span>
              <span class="campo-obscpr">
                <strong>Observações:</strong> {{ conteudo.obscpr }}<em
                  v-if="!conteudo.obscpr"
                  class="text-muted"
                >Não há.</em>
              </span>
              <span>
                <strong>Situação:</strong> {{ conteudo.sitcpr }}
              </span>
              <span>
                <em class="small">Atualizado em: {{ conteudo.dtaalt }}</em>
              </span>
              <router-link
                :to="{name:'Contratacoes:Detalhamento', params: {codpcddsp: conteudo.codpcddsp} }"
                class="botao-detalhes"
              >
                <uspBotao
                  texto="Ver Detalhes"
                  icone="fa fa-folder-open mr-2"
                />
              </router-link>
            </section>
          </div>
          
          <nav>
            <ul class="pagination">
              <li
                class="page-item"
                :class="{ 'disabled' : currentPage == 1 }"
              >
                <button
                  class="page-link"
                  aria-label="anterior"
                  @click="$emit('paginaAnterior')"
                >
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              <li
                class="page-item"
                :class="{ 'active' : currentPage == primeiroBotao }"
              >
                <button
                  class="page-link"
                  @click="$emit('irPara', primeiroBotao)"
                >
                  {{ primeiroBotao }}
                </button>
              </li>
              <li
                v-if="lastPage >= segundoBotao"
                class="page-item"
                :class="{ 'active' : currentPage == segundoBotao }"
              >
                <button
                  class="page-link"
                  @click="$emit('irPara', segundoBotao)"
                >
                  {{ segundoBotao }}
                </button>
              </li>
              <li
                v-if="lastPage >= terceiroBotao"
                class="page-item"
                :class="{ 'active' : currentPage == terceiroBotao }"
              >
                <button
                  class="page-link"
                  @click="$emit('irPara', terceiroBotao)"
                >
                  {{ terceiroBotao }}
                </button>
              </li>
              <li
                v-if="lastPage >= quartoBotao"
                class="page-item"
                :class="{ 'active' : currentPage == quartoBotao }"
              >
                <button
                  class="page-link"
                  @click="$emit('irPara', quartoBotao)"
                >
                  {{ quartoBotao }}
                </button>
              </li>
              <li class="page-item">
                <button
                  v-if="lastPage >= quintoBotao"
                  class="page-link"
                  :class="{ 'active' : currentPage == quintoBotao }"
                  @click="$emit('irPara', quintoBotao)"
                >
                  {{ quintoBotao }}
                </button>
              </li>
              <li
                class="page-item"
                :class="{ 'disabled' : currentPage == lastPage }"
              >
                <button
                  class="page-link"
                  aria-label="próximo"
                  @click="$emit('proximaPagina')"
                >
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        </section>
      </template>
    </uspCartao>
  </div>
</template>

<script>
export default {
  name: 'ListaLicitacoes',
  props: {
    listaLicitacoes: {
      type: Array,
      required: false,
      default: null
    },
    carregando: {
      type: Boolean,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    lastPage: {
      type: Number,
      required: true
    },
    totalResults: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      listaFiltradas: [],
      filtro: '',
    }
  },
  computed: {
    primeiroBotao() {
      if (this.currentPage > 3) {
        return this.currentPage - 2
      }
      return 1
    },
    segundoBotao() {
      if (this.currentPage > 3) {
        return this.currentPage - 1
      }
      return 2
    },
    terceiroBotao() {
      if (this.currentPage > 3) {
        return this.currentPage 
      }
      return 3
    },
    quartoBotao() {
      if (this.currentPage > 3) {
        return this.currentPage + 1
      }
      return 4
    },
    quintoBotao() {
      if (this.currentPage > 3) {
        return this.currentPage + 2
      }
      return 5
    }
  },
  methods: {
    indexMod(index) {
      if(this.currentPage > 3) {
        return index + 1
      }
      return index
    }
  }
}
</script>

<style lang="scss">
@import '@/componentes/estilos/_temas.scss';

@media screen and (min-width: 1200px) {
  .licitacoes {
    .list-group-item {
      position: relative;
      padding-right: 11rem;
    }
    .botao-detalhes {
      position: absolute;
      right: 1rem;
      top: 40%;
    }
  }
}

.licitacoes {
  .list-group {
    border-radius: 0.8rem;
    .list-group-item {
      border-width: 50%;
      display: flex;
      flex-direction: column;
      *:not(:last-child) {
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
      }
    }
    span strong {
      font-weight: 500;
    }
  }
}

@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} .licitacoes {
    .list-group {

      .list-group-item{
        background: map-get($tema, "usp-subelemento-cor-de-fundo");
        color: map-get($tema, "usp-elemento-cor-do-texto");
        &.active{
          background: map-get($tema, "usp-subelemento-cor-de-fundo-ativo");
        }
        &.list-group-item:hover{
          background: map-get($tema, "usp-subelemento-cor-de-fundo-desabilitado");
        }
      }
      span strong {
        color: map-get($tema, "usp-subelemento-cor-do-link");
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .row-compra {
    display: flex;
    flex-direction: column;
  }
}

.licitacoes .row-compra {
  span {
    margin-right: 0.5rem;
  }
}

@media (max-width: 770px) {
.botao-detalhes {
  display: flex;
  width: 100%;
  flex-direction: column;
}
}

.botao-detalhes {
  align-self: center;
  margin: 0.3rem 0;
}


.carregando-lista {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.campo-obscpr {
  white-space: pre-wrap;
}
</style>